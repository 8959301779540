import React, { useState, useEffect } from "react"
// Components
import WrapperRight from "../components/WrapperRight"
import ArticleCard from "../components/ArticleCard"
import ReactPaginate from "react-paginate"
import { SectionTitle } from "../components/styled"
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa"
import { Seo } from "../components/seo"

const ArticleCategory = props => {
  const nameCategory =
    props.data.allStrapiTitles.nodes[0].article_categories[0].Name
  const dataArticles = props.data.allStrapiTitles.nodes
  const [postsPerPage] = useState(10)
  const [offset, setOffset] = useState(1)
  const [allDataArticle, setAllDataArticle] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [selectedPage, setSelectedPage] = useState(1)

  const getPostData = data => {
    return data.map(news => <ArticleCard article={news} />)
  }

  const getAllPosts = async () => {
    const data = await dataArticles
    const indexLast = postsPerPage * selectedPage
    const indexFirst = indexLast - postsPerPage
    const slice = data.slice(indexFirst, indexLast)
    const postData = getPostData(slice)
    setAllDataArticle(postData)
    setPageCount(Math.ceil(data.length / postsPerPage))
  }

  const handlePageClick = e => {
    setSelectedPage(e.selected + 1)
    setOffset(selectedPage * 10)
  }

  useEffect(() => {
    getAllPosts()
    window.scrollTo(0, 0) //Do scroll up when press the number button
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  return (
    <>
      <Seo
        title={`${nameCategory} | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
        url={`https://www.919mexico.com/info-cat/${props.pathContext.slug}`}
        author={`919mexico`}
      />
      <section className="container wrapper">
        <div className="row">
          <div className="wrapper__left col-lg-9 col-lg-9">
            <hr
              className="u-line-bottom"
              style={{ border: "1px solid #fc5f5f" }}
            />
            <SectionTitle style={{ marginTop: "8px" }}>
              {nameCategory}
            </SectionTitle>
            <hr className="u-line-bottom" />
            {allDataArticle}
            <ReactPaginate
              previousLabel={<FaAngleDoubleLeft />}
              nextLabel={<FaAngleDoubleRight />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={"content-pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default ArticleCategory
export const query = graphql`
  query articleCat($slug: String) {
    allStrapiTitles(
      filter: { article_categories: { elemMatch: { Slug: { eq: $slug } } } }
      sort: { order: DESC, fields: DatePublication }
    ) {
      nodes {
        Title
        Description
        DatePublication
        LastUpdate
        Slug
        MetaDescription
        keywords
        Image {
          publicURL
        }
        Content
        article_categories {
          Name
          Slug
        }
      }
    }
  }
`
